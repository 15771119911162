<template>
  <b-overlay :show="loading" rounded="sm" variant="white">
    <!-- CODE -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Codi</h6>
      </b-col>
      <b-col cols="12">
        <b-form-input
          v-if="!rule"
          v-model="code"
          :formatter="codeFormatter"
          trim
        />
        <span v-else>{{ code }}</span>
      </b-col>
    </b-row>

    <!-- DESCRIPTIONS -->
    <b-row v-if="rule" class="mb-2">
      <b-col cols="12">
        <h6>Noms i descripcions</h6>
      </b-col>
      <b-col cols="12" v-if="descriptions.length">
        <b-list-group>
          <b-list-group-item
            v-for="ruleDescription in descriptions"
            :key="ruleDescription.uuid"
          >
            <div
              class="d-flex justify-content-between align-items-center"
              :class="{ 'mb-50': ruleDescription.description }"
            >
              <div>
                <b-badge class="mr-50">
                  {{ ruleDescription.language }}
                </b-badge>
                <b>{{ ruleDescription.name }}</b>
              </div>
              <feather-icon
                icon="TrashIcon"
                class="text-danger cursor-pointer"
                size="15"
                @click="deleteRuleDescription(ruleDescription.uuid)"
              />
            </div>
            <small
              v-if="ruleDescription.description"
              v-html="ruleDescription.description"
            >
            </small>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col cols="12" :class="{ 'mt-1': descriptions.length }">
        <b-button size="sm" variant="primary" @click="onAddDescriptionClicked"
          >Afegir</b-button
        >
      </b-col>
    </b-row>

    <!-- ACCOMMODATIONS -->
    <b-row v-if="rule" class="mb-2">
      <b-col cols="12">
        <h6>Allotjaments</h6>
      </b-col>
      <b-col cols="12" v-if="accommodationRules.length">
        <b-list-group>
          <b-list-group-item
            v-for="accommodationRule in accommodationRules"
            :key="accommodationRule.uuid"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <b>{{ accommodationRule.accommodation.name }}</b>
              </div>
              <feather-icon
                icon="TrashIcon"
                class="text-danger cursor-pointer"
                size="15"
                @click="deleteAccommodationRule(accommodationRule.uuid)"
              />
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col cols="12" :class="{ 'mt-1': accommodationRules.length }">
        <b-button size="sm" variant="primary" @click="onAddAccommodationClicked"
          >Afegir</b-button
        >
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>

    <!-- ADD RULE DESCRIPTION MODAL -->
    <b-modal
      id="add-rule-description-modal"
      title="Afegeix una nova traducció"
      scrollable
      hide-footer
    >
      <rule-description-form
        :rule="rule"
        @cancel="onAddRuleDescriptionCancel"
        @description-added="onRuleDescriptionAdded"
        @add-description-error="onAddRuleDescriptionError"
      />
    </b-modal>

    <!-- ADD RULE ACCOMMODATION MODAL -->
    <b-modal
      id="add-rule-accommodation-modal"
      title="Afegeix un nou allotjament"
      scrollable
      hide-footer
    >
      <rule-accommodations-form
        :rule="rule"
        @cancel="onAddRuleAccommodationsCancel"
        @accommodations-added="onRuleAccommodationsAdded"
        @add-accommodations-error="onAddRuleAccommodationsError"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BBadge,
  BModal,
} from "bootstrap-vue";
import RuleDescriptionForm from "@/views/rules/list/components/RuleDescriptionForm.vue";
import RuleAccommodationsForm from "@/views/rules/list/components/RuleAccommodationsForm.vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,
    BModal,
    RuleDescriptionForm,
    RuleAccommodationsForm,
  },
  props: {
    rule: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      code: null,
      min: null,
    };
  },
  computed: {
    descriptions() {
      return this.rule?.descriptions || [];
    },
    accommodationRules() {
      return this.rule?.accommodations || [];
    },
  },
  created() {
    if (this.rule) this.initForm();
  },
  methods: {
    initForm() {
      this.code = this.rule.code;
    },
    resetForm() {
      this.code = null;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      if (this.rule) this.updateRule();
      else this.createRule();
    },
    createRule() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("rules/addRule", {
            code: this.code,
          })
          .then(() => this.$emit("rule-added"))
          .catch(() => {
            this.$emit("add-rule-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    updateRule() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("rules/updateRule", {
            uuid: this.rule.uuid,
          })
          .then(() => this.$emit("rule-edited"))
          .catch(() => {
            this.$emit("edit-rule-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    deleteRuleDescription(ruleDescriptionUuid) {
      this.loading = true;
      this.$store
        .dispatch("rules/deleteRuleDescription", {
          ruleUuid: this.rule.uuid,
          ruleDescriptionUuid,
        })
        .finally(() => (this.loading = false));
    },
    deleteAccommodationRule(accommodationRuleUuid) {
      console.log({
        ruleUuid: this.rule.uuid,
        accommodationRuleUuid,
      });
      this.loading = true;
      this.$store
        .dispatch("rules/deleteRuleAccommodation", {
          ruleUuid: this.rule.uuid,
          accommodationRuleUuid,
        })
        .finally(() => (this.loading = false));
    },
    onAddDescriptionClicked() {
      this.$bvModal.show("add-rule-description-modal");
    },
    onAddRuleDescriptionCancel() {
      this.$bvModal.hide("add-rule-description-modal");
    },
    onRuleDescriptionAdded(accommodationRules) {
      console.log("accommodation rules added", accommodationRules);
      this.$bvModal.hide("add-rule-description-modal");
    },
    onAddRuleDescriptionError() {
      this.$bvModal.hide("add-rule-description-modal");
    },
    codeFormatter(code) {
      return code.replace(/\s+/g, "_").toUpperCase();
    },
    onAddAccommodationClicked() {
      this.$bvModal.show("add-rule-accommodation-modal");
    },
    onAddRuleAccommodationsCancel() {
      this.$bvModal.hide("add-rule-accommodation-modal");
    },
    onRuleAccommodationsAdded() {
      this.$bvModal.hide("add-rule-accommodation-modal");
    },
    onAddRuleAccommodationsError() {
      this.$bvModal.hide("add-rule-accommodation-modal");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
