<template>
  <b-card>
    <!-- RULES TABLE -->
    <b-static-table
      :items="mappedRules"
      :fields="rulesTableFields"
      :loading="loading"
      :button-text="$t('Nova norma')"
      show-button
      show-search
      show-actions
      @button-click="onNewRuleButtonClicked"
      @item-edit-click="onEditRuleClicked"
      @item-delete-click="onDeleteRuleClicked"
    />

    <!-- ADD RULE MODAL -->
    <b-modal
      :id="'add-rule-modal'"
      title="Afegeix un nou servei"
      scrollable
      hide-footer
    >
      <rule-form
        @cancel="onAddRuleCancel"
        @rule-added="onRuleAdded"
        @add-rule-error="onAddRuleError"
      />
    </b-modal>

    <!-- EDIT RULE MODAL -->
    <b-modal
      :id="'edit-rule-modal'"
      title="Edita el servei"
      scrollable
      hide-footer
    >
      <rule-form
        :rule="selectedRule"
        @cancel="onEditRuleCancel"
        @rule-edited="onRuleEdited"
        @edit-rule-error="onEditRuleError"
      />
    </b-modal>
  </b-card>
</template>

<script>
import RuleForm from "@/views/rules/list/components/RuleForm.vue";
import BStaticTable from "@foravila-core/components/b-static-table/BStaticTable.vue";
import {
  getRuleNameFromRuleDescriptions,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import { BCard, BModal } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BModal,
    RuleForm,
    BStaticTable,
  },
  data() {
    return {
      selectedRule: null,
      rulesTableFields: [
        { key: "name", label: this.$t("Norma"), sortable: true },
        { key: "code", label: this.$t("Codi"), sortable: true },
        {
          key: "accommodations",
          label: this.$t("Allotjaments assignats"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("Accions"),
          thStyle: { width: "95px" },
        },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["rules/loading"];
    },
    rules() {
      return this.$store.getters["rules/rules"];
    },
    mappedRules() {
      return this.rules.map((rule) => ({
        uuid: rule.uuid || this.$t("No definit"),
        name:
          getRuleNameFromRuleDescriptions(
            rule.descriptions,
            this.$i18n.locale
          ) || this.$t("No definit"),
        // name: rule.description || this.$t("No definit"),
        code: rule.code || this.$t("No definit"),
        accommodations: rule.accommodations?.length || 0,
      }));
    },
    accommodations() {
      return this.$store.getters["accommodations/accommodations"];
    },
  },
  created() {
    // Only fetch rules if they are not fetched or being fetched
    if (this.rules.length === 0 && !this.loading) {
      this.fetchRules();
    }

    if (!this.accommodations.length) {
      this.fetchAccommodations();
    }
  },
  methods: {
    fetchRules() {
      this.$store
        .dispatch("rules/fetchRules", { pagination: false, sort: "asc" })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchRules.title"),
            this.$t("errors.fetchRules.description")
          )
        );
    },
    fetchAccommodations() {
      this.$store
        .dispatch("accommodations/fetchAccommodations", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchAccommodations.title"),
            this.$t("errors.fetchAccommodations.description")
          )
        );
    },
    onNewRuleButtonClicked() {
      this.$bvModal.show("add-rule-modal");
    },
    onEditRuleClicked(ruleUuid) {
      this.selectedRule = this.rules.find((s) => s.uuid === ruleUuid);
      this.$bvModal.show("edit-rule-modal");
    },
    onDeleteRuleClicked(ruleUuid) {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("rules/deleteRule", ruleUuid)
        .then(() => {
          this.fetchRules();
          notifySuccess(
            "Servei eliminat",
            "El servei ha estat eliminat correctament"
          );
        })
        .catch(() => {
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar eliminar el servei"
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onAddRuleCancel() {
      this.$bvModal.hide("add-rule-modal");
    },
    onRuleAdded() {
      this.fetchRules();
      this.$bvModal.hide("add-rule-modal");
      notifySuccess("Servei afegit", "El servei ha estat afegit correctament");
    },
    onAddRuleError() {
      notifyError("Error", "Hi ha hagut un error al intentar afegir el servei");
    },
    onEditRuleCancel() {
      this.$bvModal.hide("edit-rule-modal");
    },
    onRuleEdited() {
      this.fetchRules();
      this.$bvModal.hide("edit-rule-modal");
      notifySuccess("Servei editat", "El servei ha estat editat correctament");
    },
    onEditRuleError() {
      notifyError("Error", "Hi ha hagut un error al intentar editar el servei");
    },
  },
};
</script>
