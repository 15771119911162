<template>
  <div id="rule-accommodatiions-form">
    <!-- CODE -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Codi de la norma</h6>
      </b-col>
      <b-col cols="12">
        {{ rule.code }}
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="12">
        <h6 class="mb-1">
          Allotjament
          <b-button
            v-if="!selectedAccommodations.length"
            variant="link"
            size="sm"
            @click="selectAllAccommodations"
          >
            Seleciona'ls tots
          </b-button>
          <b-button
            v-if="selectedAccommodations.length"
            variant="link"
            class="ml-0"
            size="sm"
            @click="clearSelection"
          >
            Esborrar selecció
          </b-button>
        </h6>
      </b-col>
      <b-col cols="12">
        <v-select
          v-model="selectedAccommodations"
          :options="accommodationOptions"
          class="accommodation-select"
          :reduce="(option) => option.value"
          multiple
          :close-on-select="false"
          :loading="loadingAccommodations"
        />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    BButton,
  },
  props: {
    rule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedAccommodations: [],
    };
  },
  computed: {
    loadingAccommodations() {
      return this.$store.getters["accommodations/loading"];
    },
    accommodations() {
      return this.$store.getters["accommodations/accommodations"];
    },
    availableAccommodations() {
      return this.$store.getters["accommodations/availableAccommodations"];
    },
    ruleAccommodations() {
      return this.rule?.accommodations || [];
    },
    accommodationOptions() {
      if (this.accommodations.length === 0) return [];
      return this.accommodations.map((a) => ({
        label: a.name,
        value: a["@id"],
      }));
    },
  },
  created() {
    this.initSelectedAccommodations();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("rules/addRuleAccommodations", {
          rule: this.rule,
          accommodations: this.selectedAccommodations,
        })
        .then((response) => this.$emit("accommodations-added", response))
        .catch(() => {
          this.$emit("add-accommodations-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    initSelectedAccommodations() {
      if (!this.ruleAccommodations.length) return;
      this.selectedAccommodations = this.ruleAccommodations.map(
        (ruleAccommodation) => ruleAccommodation?.accommodation["@id"]
      );
    },
    selectAllAccommodations() {
      this.selectedAccommodations = this.availableAccommodations.map(
        (accommodation) => accommodation["@id"]
      );
    },
    clearSelection() {
      this.selectedAccommodations = [];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#rule-accommodatiions-form {
  min-height: 530px;
}
</style>
